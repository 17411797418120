import { DEBUG } from './constants'

export type PageType = {
  title: string
  to: string
  path: string
  gotoPage?: (...rest) => string
}
//TODO: NEEDS CLEANUP TO IT'S OWN DESIGNATED PAGE
export const OVERVIEW_PAGE: PageType = {
  title: 'Overview',
  to: 'overview',
  path: '/overview/*',
  gotoPage: () => {
    return ''
  },
}

export const STAFF_PAGE: PageType = {
  title: 'Personnel',
  to: 'staff/trialStaff',
  path: '/staff/*',
  gotoPage: () => {
    return ''
  },
} as const

export const TRIAL_STAFF_PAGE: PageType = {
  title: 'Trial staff',
  to: 'trialStaff',
  path: '/trialStaff/*',
  gotoPage: () => {
    return ''
  },
} as const

export const CLINICIAN_PAGE: PageType = {
  title: 'Clinicians',
  to: 'clinician',
  path: '/clinician/*',
  gotoPage: () => {
    return ''
  },
} as const

export const SITES_PAGE: PageType = {
  title: 'Sites',
  to: 'site',
  path: '/site/*',
  gotoPage: () => {
    return ''
  },
}

//TODO: NEEDS CLEANUP TO IT'S OWN DESIGNATED PAGE
export const QUESTIONNAIRE_PAGE: PageType = {
  title: 'Questionnaire',
  to: 'questionnaire',
  path: '/questionnaire/*',
  gotoPage: () => {
    return ''
  },
}

export const ACTIVATION_PAGE: PageType = {
  title: 'Activation',
  to: 'activation',
  path: '/activation/*',
  gotoPage: () => {
    return ''
  },
}

export const PROTOCOL_PAGE: PageType = {
  title: 'Protocols',
  to: 'protocol',
  path: '/protocol/*',
  gotoPage: () => {
    return ''
  },
}
export const AUDIT_PAGE: PageType = {
  title: 'Audit trail',
  to: 'admin-audit-trail',
  path: '/admin-audit-trail/*',
  gotoPage: () => {
    return ''
  },
}

export const PROTOCOL_OVERVIEW_PAGE: PageType = {
  title: 'Protocols Overview',
  to: '',
  path: '*',
  gotoPage: () => {
    return ''
  },
} as const

export const PROTOCOL_MANAGE_PAGE: PageType = {
  title: 'Protocols Manage',
  to: 'manage',
  path: '/manage/*',
  gotoPage: () => {
    return ''
  },
} as const

export const DEBUG_PAGE: PageType = {
  title: 'Debug',
  to: 'debug',
  path: '/debug/*',
  gotoPage: () => {
    return ''
  },
} as const

const pages = [PROTOCOL_PAGE, STAFF_PAGE, SITES_PAGE, AUDIT_PAGE]

/**
 * SUB PAGES
 */
export const PROJECT_PAGES = DEBUG ? [...pages, DEBUG_PAGE] : pages
// export const PROJECT_PAGES = [OVERVIEW_PAGE, STAFF_PAGE, SITES_PAGE, QUESTIONNAIRE_PAGE, ACTIVATION_PAGE, PROTOCOL_PAGE]

/*
<SubPageLink to="overview" title="Overview"></SubPageLink>
	<SubPageLink to="managers" title="Managers"></SubPageLink>
	<SubPageLink to="site" title="Sites"></SubPageLink>
	<SubPageLink to="questionnaire" title="Questionnaire"></SubPageLink>
*/
