import React, { Suspense, useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

import { ProtocolArchivePage } from './ProtocolArchivePage'
import { ProtocolActivePage } from './ProtocolActivePage'
import { ProtocolDraftPage } from './ProtocolDraftPage'
import { useActiveDraftProtocols } from '../../hooks/useProjectProtocols'
import { ProtocolPagesOverview } from './ProtocolPagesOverview'
import { Menu } from 'antd'
import { useMatch } from 'react-router'
import { useTranslationMenu } from '../../hooks/useTranslationMenu'

export const ProtocolPage = () => {
  const { projectId } = useParams() as { projectId: string }

  //
  // match the route
  //
  const match = useMatch('/project/:projectId/protocol/:protocolId/*')

  //
  // if there is a protocolId in the url, use it to fetch the content
  //
  const protocolId = match?.params.protocolId || null

  const [activeKey, setActiveKey] = useState<string[]>([])
  const { data } = useActiveDraftProtocols(projectId)
  const navigate = useNavigate()

  const active = data.active
  const draft = data.draft

  //console.log('ProtocolPage > protocolId = ', active)
  //
  // fetch data
  //
  const { data: menuPages } = useTranslationMenu(projectId)

  useEffect(() => {
    const defaultActiveKey = protocolId ? [protocolId] : []
    setActiveKey(defaultActiveKey)
  }, [protocolId])

  const activeSubpages =
    menuPages.map((item) => {
      return { key: active?._id + '/pages/' + item.key, label: item.title }
    }) || []

  const subpages =
    menuPages.map((item) => {
      return { key: draft?._id + '/pages/' + item.key, label: item.title }
    }) || []

  const items = [
    // ACTIVE PROTOCOL
    {
      key: active?._id,
      label: 'Active',
      disabled: active?._id ? false : true,
      children: active
        ? [
            { key: active?._id + '/active', label: 'Phases' },
            { key: 'active-sites', label: 'Sites', disabled: true },
            { key: 'active-languages', label: 'Languages', disabled: true },
            {
              key: 'active-pages',
              label: 'Pages',
              children: activeSubpages,
            },
          ]
        : null,
    },

    // DRAFT PROTOCOL
    {
      key: draft?._id,
      label: 'Draft',
      disabled: draft?._id ? false : true,
      children: [
        { key: draft?._id + '/draft', label: 'Phases' },
        { key: 'draft-sites', label: 'Sites', disabled: true },
        { key: 'draft-languages', label: 'Languages', disabled: true },
        {
          key: 'draft-pages',
          label: 'Pages',
          children: subpages,
        },
      ],
    },

    { key: `./archive`, label: 'Archive' },
  ]

  const onOpenChange = (openKeys) => {
    const last = openKeys.length - 1

    const lastKey = openKeys[openKeys.length - 1]
    const justAddedDraft = lastKey === draft?._id
    const justAddedActive = lastKey === active?._id

    const keys = openKeys.filter((key, index) => {
      if (index === last) {
        return true
      }

      if (justAddedDraft && key === active?._id) {
        return false
      }

      if (justAddedActive && key === draft?._id) {
        return false
      }

      return true
    })

    setActiveKey(keys)
  }

  const menuDeSelect = (k) => {
    console.log('ProtocolPage > k = ', k)
  }

  const menuOnSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    navigate(key)
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="container px-0 mt-4">
          <div className="row" style={{}}>
            <div className="col-2">
              <Menu
                className="mt-3"
                inlineIndent={10}
                style={{ minWidth: 0, flex: 'auto' }}
                mode="inline"
                onSelect={menuOnSelect}
                onDeselect={menuDeSelect}
                //defaultOpenKeys={[activeKey]}
                //defaultSelectedKeys={['231']}
                openKeys={activeKey}
                onOpenChange={onOpenChange}
                items={items}
              />
            </div>

            <div className="col-10">
              <Routes>
                <Route
                  path={'/'}
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <div className="row" style={{}}>
                        {Object.keys(data).map((key) => {
                          const item = data[key]

                          if (!item) {
                            return null
                          }

                          return (
                            <Link
                              key={item._id}
                              to={item._id + '/' + item.status.toLowerCase()}
                              className="col-6 text-decoration-none"
                            >
                              <div
                                className="bg-white py-7 rounded-2 border-1 d-flex justify-content-center align-items-center"
                                style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}
                              >
                                <div className="py-4">
                                  <p className="fw-bold text-center opacity-75">{item.status}</p>
                                  <h4 className="text-center">{item.title}</h4>
                                </div>
                              </div>
                            </Link>
                          )
                        })}
                      </div>
                    </Suspense>
                  }
                />

                <Route
                  path={':protocolId/active/*'}
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      {active ? <ProtocolActivePage draftExist={draft?._id ? true : false} /> : null}
                    </Suspense>
                  }
                />
                <Route
                  path={':protocolId/draft/*'}
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <ProtocolDraftPage />
                    </Suspense>
                  }
                />
                <Route
                  path={'archive/*'}
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <ProtocolArchivePage />
                    </Suspense>
                  }
                />
                <Route
                  path={':protocolId/pages/:pageId/*'}
                  element={
                    <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                      <ProtocolPagesOverview activeProtocolId={active?._id}></ProtocolPagesOverview>
                    </Suspense>
                  }
                />
                {/*
          CATCH ALL - TO HANDLE IF SOMEONE NAVIGATES TO THIS PAGE withou having a protocolid
          This removes the need for a useEffect

                <Route path={'/'} element={<Navigate to={active ? activeRoutePath : draftRoutePath}></Navigate>} />
                */}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
