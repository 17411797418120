import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useProtocolPages = (projectId: string, protocolId: string, pagename: string) => {
  //
  // handle if protocolId is undefined
  //

  const p = protocolId ? ENDPOINTS.TRANSLATION_PAGES.GET_TRANSLATION_PAGES(projectId, protocolId, pagename) : null

  const { data, error, mutate, isValidating } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
    isValidating: isValidating,
  }
}
