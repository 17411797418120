/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/12/2022.
 */
import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { PROJECT_PAGE_CREATE, PROJECT_PAGE_VIEW } from '../../constants'

import { useProjects } from '../../hooks/useProjects'
import { genericDataTimeformat } from '../../util/datetime'

export const EproProjectListPage = () => {
  const { data } = useProjects()

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-12 d-flex justify-content-between mt-4 mb-3">
          <div className="">
            <h4 className="font-weight-bold">Projects</h4>
          </div>
          <div className="mr-4">
            <Link to={PROJECT_PAGE_CREATE.to}>
              <Button>
                <p className=" mb-0">Create New Project</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="row" style={{}}>
        {data.map((item: any, index: number) => {
          const to = PROJECT_PAGE_VIEW.to.replace(':projectId', item._id) + '/protocol'

          const createdDate = genericDataTimeformat(item.createdAt, 'dd MMM yyyy')

          return (
            <div className="col-12 mb-3" key={index}>
              <div className="w-100 p-4 d-flex item-shadow">
                <div className="pl-2 w-25">
                  <div className="d-flex">
                    {/*                    <p className="mb-0 me-1 p-mini alpha-80 fw-bold">Project </p>*/}
                    <p className="p-mini opacity-25 text-black">{item._id}</p>
                  </div>
                  <h5 className="mb-1 fw-bold text-black pe-3 text-truncate">{item.title}</h5>
                </div>

                <div className="w-25">
                  <p className="mb-0 p-mini alpha-80">Display name</p>
                  <p className="mb-0 fw-bold alpha-80 text-truncate ">
                    {item.displayName ? item.displayName : 'not set'}
                  </p>
                </div>

                <div style={{ width: '15%' }}>
                  <p className="mb-0 p-mini alpha-80">Status</p>
                  <p className="mb-0 fw-bold alpha-80 ">{item.status ? item.status : '-'}</p>
                </div>

                <div style={{ width: '10%' }}>
                  <p className="mb-0 p-mini alpha-80">Created</p>
                  <p className="mb-0 fw-bold alpha-80 text-truncate ">{createdDate}</p>
                </div>

                <div className="w-25 d-flex justify-content-end align-items-center">
                  <div className="pr-4"></div>

                  <Link className="" to={to}>
                    <Button type="primary">View project</Button>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
