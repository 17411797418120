import dayjs from 'dayjs'
import { format } from 'date-fns'

/*
Types
 */
export type StoreDateTimeType = {
  h?: number
  m?: number
  y?: number
  M?: number
  D?: number
}

export type StoreDateRangeType = {
  startAt: StoreDateTimeType
  endAt: StoreDateTimeType
}
export const convert2Date = (value: StoreDateTimeType) => {
  const { y, M, D } = value
  return dayjs()
    .set('y', y!)
    .set('M', M! - 1)
    .set('D', D!)
}

export const genericDataTimeformat = (createdAt: string, timeformat: string = 'E d LLL yyyy HH:mm:ss') => {
  if (!createdAt) {
    return 'not defined'
  }
  return format(new Date(createdAt), timeformat)
}
