import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useBodyPartsQuestion = (projectId: string, phaseId: string) => {
  const p = ENDPOINTS.PHASES.byBodyPartsQuestion(projectId, phaseId)
  const { data, mutate, error } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? null : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
