/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import { Link, Route, Routes, useParams } from 'react-router-dom'

import { Button, Popconfirm } from 'antd'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import Alert from 'antd/lib/alert'
import { omit } from 'lodash'
import { post } from '../../service/API'

import { getFormType } from '../../components/form/formFactory'
import { FormLabel } from '../../components/form/FormLabel'
import { JustificationInput } from '../../components/form/JustificationInput'
import { openNotification } from '../../components/notifications/openNotification'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { CRUD_VIEW_TYPE, PERSONNEL_TYPE_ENUM, SiteType, VIEW_TYPE } from '../../Types'
import { isValidEmail } from '../../util/isValidEmail'
import { populateConfig } from '../../util/populateConfig'
import { displayStaffStatus } from '../../util/staffUtils'
import { InviteTrialstaff } from '../clinician/InviteTrialstaff'
import { SideBarAddSites } from '../clinician/SideBarAddSites'
import { SiteBarSiteList } from '../clinician/SideBarSiteList'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { RetireStaff } from './RetireStaff'
import { StaffPii } from './StaffPii'
import { getTrialStaff } from './trialStaffConfig'

type Props = {
  closeDrawer: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

const SITES_FORM_KEY = 'sites'
const PHONE_FORM_KEY = 'phone'

export const TrialStaffCRUD = ({ onClose, closeDrawer, viewType = CRUD_VIEW_TYPE.CREATE }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const p = userId ? ENDPOINTS.TRIALSTAFF.byStaffId(projectId, userId) : ''

  const { data: dataConfig } = useProjectConfig()

  const { data, mutate } = useByEndpoint(p)

  //
  // build config object - to populate form
  //
  const configObj = viewType === 'UPDATE' ? populateConfig(getTrialStaff(), data, viewType) : getTrialStaff()

  const formHook = useForm()

  const { setValue, getValues, handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const initSites = useMemo(() => {
    const defaultSiteArr = data?.sites || []
    return viewType === CRUD_VIEW_TYPE.CREATE ? [] : defaultSiteArr
  }, [viewType, data?.sites])

  const [showAddSiteDrawer, setAddSiteDrawer] = useState<boolean>(false)
  const [selectedSites, setSelectedSites] = useState<SiteType[]>(initSites)

  const [openPop, setOpenPop] = useState(false)

  const [popconfirmHeader, setPopconfirmHeader] = useState('')
  const [popconfirmBody, setPopconfirmBody] = useState('')

  useEffect(() => {
    setSelectedSites(initSites)

    const a = initSites.map((site) => site._id)

    formHook.register(SITES_FORM_KEY)
    formHook.setValue(SITES_FORM_KEY, viewType === CRUD_VIEW_TYPE.VIEW ? [] : a)
  }, [formHook, viewType, initSites])

  useEffect(() => {
    formHook.setValue(PHONE_FORM_KEY, data?.phone)
  }, [formHook, data?.phone])

  const onSubmit = (formData, e) => {
    e.preventDefault()

    if (!isValidEmail(formData.email)) {
      setErrorState({ message: 'Please provide a valid email address' })
      return
    } else {
      setErrorState(null)
    }

    if (formData.phone.countryCode === '' || formData.phone.tel === '' || formData.phone.tel === null) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    } else {
      setErrorState(null)
    }

    const createData = { ...formData, projectId: projectId }
    const updatedData = { ...data, ...formData }

    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    console.log('TrialStaffCRUD > sendData > ', sendData)

    const omittedObj = omit(sendData, ['inviteSent'])

    const updatedSendData = { ...omittedObj }

    return post(ENDPOINTS.TRIALSTAFF.ACTION(projectId), updatedSendData, method)
      .then(() => {
        const title = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Update trial staff' : 'Create trial staff'
        const msg =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Trial staff has been successfully updated'
            : 'Trial staff has been successfully created'
        openNotification(title, msg, 'happy')

        mutate()

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
          setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const updateSelectedSites = (theSites) => {
    const arrOfIds = theSites.map((item, index) => {
      return item._id
    })

    setValue(SITES_FORM_KEY, arrOfIds)
    setSelectedSites(theSites)
  }

  const removeSelectedSite = (id) => {
    const cur = getValues(SITES_FORM_KEY)

    const updated = cur.filter((item) => {
      return item !== id
    })

    const updatedSites: SiteType[] = selectedSites.filter((item) => {
      return item._id !== id
    })

    setValue(SITES_FORM_KEY, updated)
    setSelectedSites(updatedSites)
  }

  const onError = (err, e) => {
    e.preventDefault()
    cancel()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
    //closeDrawer(true)
  }

  const open = () => {
    setAddSiteDrawer(true)
  }

  const closeAddSiteDrawer = () => {
    setAddSiteDrawer(false)
  }

  const showPopconfirm = () => {
    if (formHook.getValues('name') === undefined || formHook.getValues('name') === '') {
      setErrorState({ message: 'Please provide a name' })
      return
    } else {
      setErrorState(null)
    }

    if (!isValidEmail(formHook.getValues('email'))) {
      setErrorState({ message: 'Please provide a valid email address' })
      return
    } else {
      setErrorState(null)
    }

    if (formHook.getValues('phone.countryCode') === undefined || formHook.getValues('phone.countryCode') === '') {
      setErrorState({ message: 'Please provide a valid country code' })
      return
    } else {
      setErrorState(null)
    }

    if (formHook.getValues('phone.tel') === undefined || formHook.getValues('phone.tel') === '') {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    } else {
      setErrorState(null)
    }

    if (formHook.getValues('type') === undefined) {
      setErrorState({ message: 'Please provide a trial staff type' })
      return
    } else {
      setErrorState(null)
    }

    const data = { oldEmail: null, newEmail: formHook.getValues('email') }

    return post(ENDPOINTS.TRIALSTAFF.countByEmail(projectId), data, 'POST')
      .then((result) => {
        setOpenPop(true)
        if (result.data === 0) {
          setPopconfirmHeader('New user 🎉')
          setPopconfirmBody('This will be the first user with this email')
        } else {
          setPopconfirmHeader(result.data + ' users with matching email: ' + formHook.getValues('email'))
          setPopconfirmBody('All users with matching emails will have their PII updated. Confirm?')
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const cancel = () => {
    setOpenPop(false)
  }

  return (
    <>
      <Routes>
        <Route
          path="/pii"
          element={
            <ProjectDrawer width="30%" projectId={''} to="../" title="Update trial staff pii">
              <StaffPii staffType={PERSONNEL_TYPE_ENUM.TRIAL_STAFF}></StaffPii>
            </ProjectDrawer>
          }
        />
        <Route
          path="/edit/*"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer
                projectId={projectId}
                width="30%"
                to="../"
                title="Edit trial staff information"
                header={
                  <div className="">
                    <Link to={'pii'} className="">
                      <Button type="default" className="">
                        Update trial staff pii
                      </Button>
                    </Link>
                  </div>
                }
              >
                <TrialStaffCRUD closeDrawer={closeDrawer} viewType="UPDATE"></TrialStaffCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/retire"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="Revoke/Reactivate access">
              <RetireStaff type={PERSONNEL_TYPE_ENUM.TRIAL_STAFF}></RetireStaff>
            </ProjectDrawer>
          }
        />
      </Routes>

      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              // -------------------------------------------------------------------------------------
              // If time allows, investigate how to do it directly through the config (by injecting)
              // -------------------------------------------------------------------------------------
              const comp = getFormType(item, index, formHook, [], undefined, dataConfig?.countries, viewType)
              return comp
            })}
          </div>

          {data && data.type !== 'CTM' && data.type !== 'DM' ? (
            <div className="col-12 mb-4">
              <SiteBarSiteList
                open={open}
                selectedSites={selectedSites}
                removeSelectedSite={removeSelectedSite}
                viewType={CRUD_VIEW_TYPE.CREATE}
                headerText="Update site access"
              ></SiteBarSiteList>
            </div>
          ) : (
            <></>
          )}

          <div className="col-12">
            <hr />
          </div>

          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}

          <div className="col-12 mt-3 mb-2">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>

            {viewType === CRUD_VIEW_TYPE.UPDATE ? (
              <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
                Save changes
              </Button>
            ) : (
              <Popconfirm
                title={popconfirmHeader}
                description={popconfirmBody}
                open={openPop}
                onConfirm={handleSubmit(onSubmit, onError)}
                onCancel={cancel}
                okText="Confirm"
                cancelText="Cancel"
              >
                <Button type="primary" onClick={showPopconfirm}>
                  Create user
                </Button>
              </Popconfirm>
            )}
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <FormLabel label="Type"></FormLabel>
            <h6 className="fw-bold">{data.type ? data.type : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Name"></FormLabel>
            <h6 className="fw-bold">{data.name ? data.name : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Email"></FormLabel>
            <h6 className="fw-bold">{data.email ? data.email : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phone"></FormLabel>
            <h6 className="fw-bold">{data.phone ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{displayStaffStatus(data)}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Job title"></FormLabel>
            <h6 className="fw-bold">{data.jobTitle || '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Organization"></FormLabel>
            <h6 className="fw-bold">{data.organization || '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Created by"></FormLabel>
            <h6 className="fw-bold">{data.createdBy || '-'}</h6>
          </div>

          {data && data.type !== 'CTM' && data.type !== 'DM' ? (
            <div className="col-12 mb-4">
              <SiteBarSiteList selectedSites={selectedSites}></SiteBarSiteList>
            </div>
          ) : (
            <></>
          )}

          <div className="col-12 d-flex justify-content-end">
            <Link to="edit">
              <Button type="primary">Edit Trial Staff</Button>
            </Link>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 mt-3">
            <InviteTrialstaff
              data={data}
              mutate={mutate}
              userId={userId}
              userType={PERSONNEL_TYPE_ENUM.TRIAL_STAFF}
              onClose={onClose}
            ></InviteTrialstaff>
          </div>
        </div>
      )}
      {showAddSiteDrawer ? (
        <ProjectDrawer setVisible={closeAddSiteDrawer} projectId={''} width={550} to="." title="Site list">
          <SideBarAddSites projectId={projectId} setSelectedSites={updateSelectedSites} selectedSites={selectedSites} />
        </ProjectDrawer>
      ) : (
        <></>
      )}
    </>
  )
}
