import React from 'react'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/06/2023.
 */

type PhaseContentLabelType = {
  label: string
  children?: React.ReactElement | null
}

export const PhaseContentLabel = ({ label = 'some label', children = null }: PhaseContentLabelType) => {
  return (
    <div className="col-12 px-3 mt-3 mb-3 d-flex align-items-center justify-content-between">
      <p className="fw-bold p-small text-primary" style={{ letterSpacing: 0.5 }}>
        {label}
      </p>
      {children}
    </div>
  )
}
