import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as DragHandle } from '../../assets/drag-vert.svg'
import dots from '../../assets/dots.png'
import { reorder } from '../../components/drag/DraggableQuestionUtil'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { DragOutlined } from '@ant-design/icons'
import { AlertHandler } from '../../util/AlertHandler'

export const PhaseOrderModal = ({ phases, mutate }) => {
  const { projectId } = useParams() as { projectId: string }

  const navigate = useNavigate()

  const [items, setItems] = useState<any>(phases)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [errorState, setErrorState] = useState<any>(undefined)

  console.log('PhaseOrderModal > phases = ', phases)

  useEffect(() => {
    setIsModalOpen(true)
    //setErrorState(undefined)
  }, [setIsModalOpen])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    setItems(reorder(items, result.source.index, result.destination.index))
  }

  const handleSubmit = () => {
    setConfirmLoading(true)
    setErrorState(undefined)

    post(ENDPOINTS.PHASES.order(projectId), items, 'PUT')
      .then(() => {
        setConfirmLoading(false)
        setIsModalOpen(false)
        mutate()
      })
      .catch((error) => {
        setErrorState(error)
        setConfirmLoading(false)
        console.log('PhaseVisitList > error = ', error)
      })
  }

  const layoutChange = (e) => {
    const toPath = '../'

    if (!e) {
      navigate(toPath)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <Modal
      //title={'Update order of all phases in the project'}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={'60%'}
      className="px-0"
      afterOpenChange={layoutChange}
      confirmLoading={confirmLoading}
    >
      <div className="container px-0">
        <div className="row">
          <div className="col-12 mb-4 mt-2">
            <h5>Update order</h5>
            <p>Rearrange the phases to change the order</p>
          </div>

          <div
            className="col-12 px-4 pt-6 pb-4 mb-1 "
            style={{
              border: '1px solid ',
              borderColor: '#6d897b',
              backgroundColor: 'rgba(109,137,123,0.02)',
              boxShadow: 'inset 0 0 10px 2px  rgba(109,137,123,.3)', //'0px 4px 6px -2px rgba(0, 0, 0, 0.08)',
              backgroundImage: `url(${dots})`,
              backgroundPosition: `center center`,
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={
                      {
                        /*background: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey'*/
                      }
                    }
                  >
                    {items.map((phase, index) => {
                      return (
                        <Draggable key={phase._id} draggableId={phase._id} index={index}>
                          {(provided, snapshot) => {
                            if (snapshot.isDragging) {
                              const { offsetTop, offsetLeft } = provided.draggableProps!.style as {
                                offsetTop: string
                                offsetLeft: string
                              }
                              //@ts-ignore
                              provided.draggableProps.style!.left! = offsetLeft
                              //@ts-ignore
                              provided.draggableProps.style!.top! = offsetTop
                            }

                            return (
                              <div className="col-12 mb-3  " ref={provided.innerRef} {...provided.draggableProps}>
                                <PhaseOrderListItem key={index} item={phase} provided={provided}></PhaseOrderListItem>
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <AlertHandler errorState={errorState}></AlertHandler>
      </div>
    </Modal>
  )
}

const PhaseOrderListItem = ({ item, provided }) => {
  const itemTypeStr = item.type?.toLowerCase() || ''
  const itemType = itemTypeStr.replace('_', ' ')

  return (
    <div className="">
      <div
        className="col-12 d-flex h-100 noselect "
        style={
          {
            //boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
            //border: '1px solid #aaaaaa',
          }
        }
        {...provided.dragHandleProps}
      >
        <div
          className="py-3 px-2 d-flex align-items-center "
          style={{
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            //background: '#e6e6e6',
            //background: 'linear-gradient(#EAEAEA,#F2F2F2)',
            //background: 'linear-gradient(#EAEAEA,#F2F2F2)',
            background: 'linear-gradient(#6d897b,#6d897b)',
            border: '1px solid ',
            borderColor: '#D0D0D0',
          }}
        >
          <DragHandle className="" style={{ fill: 'white', width: 8, height: 12, opacity: 1 }}></DragHandle>
        </div>
        <div
          className="py-3 bg-white d-flex w-100 h-100 align-items-center"
          style={{
            border: '1px solid #aaaaaa',
            boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.08)',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          <div className="ps-5 py-2" style={{ width: '40%' }}>
            <p className="p-mini opacity-50 ">Phase title</p>
            <h6 className="mb-1 fw-bold text-capitalize">{item.title}</h6>
          </div>

          <div className=" " style={{ width: '40%' }}>
            <p className="p-mini opacity-50 ">{'type'}</p>
            <h6 className="mb-1 fw-bold text-capitalize ">{itemType}</h6>
          </div>

          <div className="d-flex align-items-center  " style={{ width: '20%' }}>
            <div className="d-flex justify-content-end w-100 pe-4" style={{}}>
              <p className=" ">Drag to reorder</p>
              <DragOutlined className="ms-2" style={{ fontSize: 18 }} />
              {/*<DragHandleHoz className="ms-3" style={{ marginTop: 6, width: 20, height: 12 }}></DragHandleHoz>*/}
            </div>
          </div>
          {/*<div className="pe-3 mt-3 d-flex justify-content-end align-items-center " style={{ width: '50%' }}>
            <p className="mb-3">drag </p>
            <DragHandle
              className=""
              style={{ width: 28, height: 10, opacity: 0.4, transform: 'rotate(90deg)' }}
            ></DragHandle>
          </div>*/}
        </div>
      </div>
    </div>
  )
}
