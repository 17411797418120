/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 10/11/2024.
 */
import { FormLabel } from '../../components/form/FormLabel'
import { RESOURCE_STATUS } from '../../Types'
import { FormViewItem } from '../../components/form/FormViewItem'
import React from 'react'

type Props = {
  data: { title: string; type: string; status: keyof typeof RESOURCE_STATUS }
  ctaConfig: string[]
  children: React.ReactNode
}

export const GroupView = ({ data, ctaConfig, children }: Props) => {
  console.log('data = ', data)

  console.log('GroupCRUD > ctaConfig = ', ctaConfig)
  if (!data) {
    return null
  }

  return (
    <div className="container-fluid px-0">
      <div className="row" style={{}}>
        <div className="col-12 mb-1 d-flex justify-content-between">
          <div className="">
            <FormLabel label="Title"></FormLabel>
            <h6 className="fw-bold">{data.title}</h6>
          </div>
          <div className="">
            <div
              className="rounded-pill d-inline-block px-3 py-1 mt-1  "
              style={{ background: data.status === RESOURCE_STATUS.ACTIVE ? '#cbe1cd' : '#f1f1f1' }}
            >
              <p
                className=" "
                style={{ fontSize: '0.7rem', color: data.status === RESOURCE_STATUS.ACTIVE ? '#799f7b' : '#818181' }}
              >
                {data.status}
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 mb-1">
          <FormLabel label="Type"></FormLabel>
          <h6 className="fw-bold">{data.type}</h6>
        </div>
        <div className="col-12 px-3 mt-3">
          <div className="row pt-2 pb-3 rounded" style={{ background: '#f3f3f3' }}>
            {ctaConfig.map((item, index) => {
              return <FormViewItem key={index} item={item}></FormViewItem>
            })}
          </div>
        </div>
        {children}
        {/* <div className="col-12">
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              return <FormViewItem key={index} item={item}></FormViewItem>
            })}
        </div>*/}
      </div>
    </div>
  )
}
