import { Alert, Button } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { destroy } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'

type DeletePhaseProps = {
  onClose?: null | ((bool: boolean) => void)
  closeDrawer?: null | ((bool: boolean) => void)
}

export const DeletePhase = ({ closeDrawer, onClose = null }: DeletePhaseProps) => {
  const { phaseId, projectId, protocolId } = useParams() as { phaseId: string; projectId: string; protocolId: string }
  const [errorState, setErrorState] = useState<any>()

  const deleteClick = () => {
    setErrorState(undefined)

    destroy(ENDPOINTS.PHASES.delete(projectId, phaseId, protocolId))
      .then((result) => {
        if (onClose) {
          onClose(false)
        }
        if (closeDrawer) {
          closeDrawer(false)
        }

        return result
      })
      .catch((error) => {
        setErrorState(error)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12 mb-2">
          <h6>Deleting a phase is only allowed for cloned DRAFT phases.</h6>
          <h6>All questionnaires and questions will be deleted as well.</h6>
        </div>

        <Button type="primary" onClick={deleteClick} danger>
          Delete the phase
        </Button>

        {errorState ? (
          <Alert className="mt-4" showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
