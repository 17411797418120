/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/06/2023.
 */
import React from 'react'
import { Button } from 'antd'
import { QuestionnaireListItem } from '../questionnaire/QuestionnaireListItem'
import { PhaseContentLabel } from './PhaseContentLabel'
import { LanguageISOCode } from '../../Types'
import { Link } from 'react-router-dom'

import { GroupList } from '../group/GroupList'
import { PhaseHeader } from './PhaseHeader'

type Props = {
  phase: any
  language: LanguageISOCode
}

export const PhaseListItem = ({ phase, language }: Props) => {
  const phaseQuestionnaires = phase.questionnaires || []

  return (
    <div className="row mb-4 pb-1  " style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA' }}>
      <PhaseHeader
        phase={phase}
        buttons={
          <Link to={`phase/${phase._id}`} className="pe-3">
            <Button type="primary">View phase</Button>
          </Link>
        }
      />

      <PhaseContentLabel label="QUESTIONNAIRES"></PhaseContentLabel>

      {/*------------------- QUESTIONNAIRES -----------------------------  */}

      <div className="col-12 px-4" style={{}}>
        <div className="row" style={{}}>
          {phaseQuestionnaires.length !== 0 ? (
            phaseQuestionnaires.map((ques, qIndex) => {
              //
              // Depend on the lifecycle of the group questionnaire.
              //
              if (ques.type === 'GROUP') {
                return null
              }

              return (
                <QuestionnaireListItem
                  key={qIndex}
                  item={ques}
                  to={`phase/${phase._id}/questionnaire/${ques._id}`}
                  language={language}
                ></QuestionnaireListItem>
              )
            })
          ) : (
            /*------------------- NO QUESTIONNAIRE IN THIS PHASE ---------------  */
            <div className="col-12 px-4 mb-3">
              <div className="row pb-2 border rounded">
                <div className="col-12">
                  <div className="w-100 pt-3 pb-2 px-2 d-flex justify-content-center align-items-center">
                    <p className="me-2 opacity-75">No questionnaires in this phase</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/*
       ----------------------------------
        Groups and group questionnaires
        ----------------------------------
      */}

      <GroupList headerButtons={null} isDraft={false} phase={phase} language={language}></GroupList>
    </div>
  )
}
