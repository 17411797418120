import React from 'react'
import { Phase } from '../../Types'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/11/2024.
 */
type Props = {
  phase: Phase
  buttons?: React.ReactElement | null
}

export const PhaseHeader = ({ phase, buttons = null }: Props) => {
  return (
    <div
      className="col-12  py-2  d-flex align-items-center bg-primary"
      style={{
        boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)',
        //background: '#6d897b',
        borderTop: '4px solid #526b5c',
      }}
    >
      <div className="ps-2 my-3 text-white" style={{ width: '23%' }}>
        <p className="p-mini opacity-50 ">
          {phase._id}
          <span className="ps-2 opacity-50">{phase.status}</span>
        </p>
        <h5 className="mb-1 fw-bold ">{phase.title}</h5>
      </div>

      <div className="ps-2 my-3 text-white " style={{ width: '12%' }}>
        <p className="p-mini opacity-50 ">duration</p>
        <p className="mb-1 fw-bold ">{phase.duration} days</p>
      </div>
      <div className="ps-2 my-3 text-white" style={{ width: '20%' }}>
        <p className="p-mini opacity-50 text-white">type</p>
        <p className="mb-1 fw-bold text-white">{phase.type}</p>
      </div>

      <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
        <p className="p-mini opacity-50 text-white">short compliance</p>
        <p className="mb-1 fw-bold text-white">{phase.shortCompliance ? phase.shortCompliance + ' days' : '-'} </p>
      </div>
      <div className="ps-2 my-3 text-white" style={{ width: '15%' }}>
        <p className="p-mini opacity-50 text-white">overall compliance</p>
        <p className="mb-1 fw-bold text-white">{phase.overallCompliance ? phase.overallCompliance + '%' : '-'}</p>
      </div>

      <div className="d-flex justify-content-end" style={{ width: '15%' }}>
        {buttons}
      </div>
    </div>
  )
}
