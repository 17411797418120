export const getGroupConfig = (projectConfig) => {
  return [
    {
      id: 'title',
      type: 'TEXT',
      placeholder: 'Add title',
      label: 'Title',
      prefix: '',
      postfix: '',
      value: '',
      validation: { required: true },
      errorMsg: 'Title is required',
    },
    {
      id: 'type',
      type: 'DROPDOWN',
      placeholder: '',
      label: 'Type',
      prefix: '',
      postfix: '',
      value: '',
      options: projectConfig.groupTypes.map((type) => ({ value: type, label: type })),
      validation: { required: true },
      errorMsg: 'A type must be selected',
      disabled: false,
    },
  ] as const
}

export const getGroupCtaElement = [
  {
    id: 'section',
    type: 'SECTION',
    placeholder: '',
    label: 'Call to action',
    prefix: '',
    postfix: '',
    value: 'The element that will be displayed in the portal',
    validation: { required: true },
    errorMsg: '',
    disabled: true,
  },
  {
    id: 'cta.title',
    type: 'TEXT',
    placeholder: 'Headline',
    label: 'Headline',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
    errorMsg: 'Title is required',
  },
  {
    id: 'cta.description',
    type: 'TEXT',
    placeholder: 'Description text',
    label: 'Description',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
    errorMsg: 'Title is required',
  },
  {
    id: 'cta.label',
    type: 'TEXT',
    placeholder: 'Button label',
    label: 'Button label',
    prefix: '',
    postfix: '',
    value: '',
    validation: { required: false },
    errorMsg: 'Title is required',
  },
]
