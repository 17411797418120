/**
 * Created by @author Søren Tramm
 */
import React from 'react'
import { Button } from 'antd'
import { PhaseContentLabel } from '../../phase/PhaseContentLabel'
import { ProtocolQuestionnaireListItem } from './ProtocolQuestionnaireListItem'
import { Link } from 'react-router-dom'
import { LanguageISOCode, RESOURCE_STATUS } from '../../../Types'
import { GroupList } from '../../group/GroupList'
import { PhaseHeader } from '../../phase/PhaseHeader'

type Props = {
  phase: any
  protocol: any
  language: LanguageISOCode
}

export const ProtocolOverviewListItem = ({ phase, protocol, language }: Props) => {
  const questionnaires = phase?.questionnaires || []

  const mayBeEdited = phase?.status === RESOURCE_STATUS.DRAFT

  const addGroupPath = `phase/${phase._id}/create-group/`

  console.log('ProtocolOverviewListItem > phase = ', phase)

  return (
    <div className="row mb-4 pb-1  " style={{ boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)', background: '#FAFAFA' }}>
      <PhaseHeader
        phase={phase}
        buttons={
          <>
            {phase.status === RESOURCE_STATUS.ACTIVE ? (
              <Link to={`./clone-phase/${phase._id}`}>
                <Button className="me-2 border-0 text-white" style={{ background: '#557161' }} type="text">
                  View phase
                </Button>
              </Link>
            ) : (
              <Link to={`./view-phase/${phase._id}`}>
                <Button type="text" className="text-white border-0 me-2" style={{ background: '#557161' }}>
                  View phase
                </Button>
              </Link>
            )}
          </>
        }
      />

      <>
        {/*QUESTIONNAIRES*/}
        <PhaseContentLabel label="QUESTIONNAIRES">
          {mayBeEdited ? (
            <div className="col-8 d-flex justify-content-end  ">
              <Link to={`phase/${phase._id}/create-questionnaire/`}>
                <Button type="text" className="py-0 my-0">
                  <p className="p-small text-primary">Add questionnaire</p>
                </Button>
              </Link>
            </div>
          ) : null}
        </PhaseContentLabel>

        <div className="col-12 px-4" style={{}}>
          <div className="row" style={{}}>
            {questionnaires.length !== 0 ? (
              questionnaires.map((ques, qIndex) => {
                console.log('ProtocolOverviewListItem > ques = ', ques.type)

                //
                // THE Group type should be collected from the backend and not hardcoded here
                //
                // This happends becuase we get all questionnaire tied to the phaseId.
                //

                if (ques.type === 'GROUP') {
                  return null
                }

                return (
                  <ProtocolQuestionnaireListItem
                    key={qIndex}
                    item={ques}
                    to={`./${phase._id}`}
                    mayClone={phase.status !== RESOURCE_STATUS.ACTIVE}
                    language={language}
                  />
                )
              })
            ) : (
              <NoContent
                to={''}
                label="No questionnaires added to the protocol for this phase, yet "
                btnLabel="Add questionnaire"
              ></NoContent>
            )}
          </div>
        </div>

        <GroupList
          isDraft={true}
          phase={phase}
          language={language}
          noContent={
            phase.groups.length === 0 ? (
              <NoContent to={addGroupPath} label="No Groups found for this phase" btnLabel="Add group"></NoContent>
            ) : null
          }
          headerButtons={
            mayBeEdited ? (
              <div className="col-8 d-flex justify-content-end  ">
                <Link to={addGroupPath}>
                  <Button type="text" className="py-0 my-0">
                    <p className="p-small text-primary">Add Group</p>
                  </Button>
                </Link>
              </div>
            ) : null
          }
        ></GroupList>
      </>
    </div>
  )
}

const NoContent = ({ to, label = '', btnLabel = '' }) => {
  return (
    <div className="col-12 mt-1 mb-2 ">
      <div className="row pb-2 border rounded-2" style={{ boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)' }}>
        <div className="col-12 mt-2">
          <div className="w-100 pt-3 pb-1 px-2 d-flex justify-content-center align-items-center">
            <p className="p-small text-primary me-2 ">{label}</p>
          </div>
          <div className="d-flex justify-content-center mb-3 mt-1">
            <Link to={to}>
              <Button type="default" className="py-0 my-0">
                <p className="p-small text-primary">{btnLabel}</p>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
