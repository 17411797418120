import React, { useEffect, useState } from 'react'

import { authStore } from './store/auth/authStore'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorView } from './components/error/ErrorView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login/LoginPage'
import { Main } from './Main'

import { ConfigProvider } from 'antd'
import { ENDPOINTS } from './service/ENDPOINTS'
import { ErrorType, getReq, post } from './service/API'

const checkAdmin = (sub) => {
  const p = ENDPOINTS.ADMINISTRATORS.GET_USER + sub
  return getReq(p)
}
const createAdmin = (sub, email) => {
  const p = ENDPOINTS.ADMINISTRATORS.CREATE
  const data = { sub: sub, email: email }
  return post(p, data)
}

console.log('App > force deploy 22= ')
function App() {
  const user: any = authStore((state) => state.user)
  const [userConfirm, setUserConfirm] = useState(false)
  const init = authStore((state) => state.init)
  const [error, setError] = useState<{ type: 'AUTH' | 'GROUP'; error: ErrorType } | null>(null)

  useEffect(() => {
    if (!user) {
      init().catch((error: any) => {
        console.log(' App > init .--= ', error)
        setError({ type: 'AUTH', error: error })
      })
    }
    //
    // We need to sync congnito user with admins in the database
    // creation should only happen once per user
    //
    if (user?.attributes && !userConfirm) {
      const sub = user.attributes.sub
      const email = user.attributes.email

      checkAdmin(sub)
        .then((admin) => {
          if (admin.data) {
            setUserConfirm(true)
            setError(null)
          } else {
            createAdmin(sub, email).then((result) => {
              setUserConfirm(result.result)
              setError(null)
            })
          }
        })
        .catch((error) => {
          setError({ type: 'GROUP', error: error })
        })
    }
  }, [init, user, userConfirm])

  // show spinner if we are waiting for user to be confirmed
  if (user?.attributes && !userConfirm && !error) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  if (!user && !error) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  if (user && error?.type === 'GROUP') {
    // this should happen if user is not part of the right user group
    return <ErrorView error={error.error}></ErrorView>
  }

  const shouldRedirect = !user || user.challengeName
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#526b5b',
          colorError: '#e52222',
        },
        components: {
          Select: { zIndexPopup: 1500 },
          Popconfirm: { zIndexPopup: 1500 },
          Menu: {
            itemSelectedBg: 'none',
            itemSelectedColor: '#2b352e',
            subMenuItemBg: 'none',
            itemColor: '#a1a1a1',
            itemBg: 'none',
          },
          DatePicker: { zIndexPopup: 1500 },
        },
      }}
    >
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <Routes>
            <Route path="/login/*" element={<LoginPage />} />
            <Route path="/*" element={shouldRedirect ? <Navigate replace to="/login" /> : <Main />} />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
