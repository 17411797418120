import { Button, Input } from 'antd'
import { GroupHeader } from '../../components/display/GroupHeader'
import { useParams } from 'react-router'
import { useState } from 'react'
import { getReq, post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { genericDataTimeformat } from '../../util/datetime'
import Spinner from '../../components/spinner/Spinner'

export const DebugEligibility = () => {
  const { projectId } = useParams() as { projectId: string }

  const [screeningId, setScreeningId] = useState('')
  const [assignemtList, setAssignemtList] = useState([])

  const [loading, setLoading] = useState<boolean>(false)

  const onChange = (e) => {
    setScreeningId(e.target.value)
  }

  const loadInfo = () => {
    setLoading(true)
    //const key = ENDPOINTS.PROJECTS.byProjectId(projectId)

    return getReq(ENDPOINTS.DEBUG.subjectAssignments(projectId, screeningId))
      .then((result) => {
        setAssignemtList(result.data)

        setLoading(false)
      })
      .catch((error) => {
        alert(error.message)
        setLoading(false)
      })
  }

  return (
    <div className="row mt-5 mb-7 group-item" style={{}}>
      <GroupHeader label="Eligibility" byline="Inject answers into subject assignments">
        <div className="col-10 py-3 d-flex align-items-center">
          <div className="w-100">
            <Input placeholder="Subject screening Id" onChange={onChange} defaultValue={screeningId} />
          </div>
        </div>
        <div className="col-2 d-flex  align-items-center">
          <Button type="primary" onClick={loadInfo} className="w-100">
            Load info
          </Button>
        </div>

        <div className="col-12 mt-2">
          {loading ? (
            <div className="d-flex justify-content-center mb-6">
              <Spinner></Spinner>
            </div>
          ) : (
            <div className="row " style={{}}>
              {assignemtList.length > 0
                ? assignemtList.map(
                    (
                      assignment: {
                        _id: string
                        answers: any[]
                        questions: any[]
                        startDate: string
                        endDate: string
                        questionnaireId: string
                      },
                      index
                    ) => {
                      console.log('DebugEligibility > assignment = ', assignment)

                      return (
                        <div key={index} className="col-12 mb-2">
                          <h5 className="mb-2">Assignment</h5>
                          <div className="d-flex">
                            <p className="mb-2 p-small me-3">
                              Window start:{' '}
                              <span className="fw-bold">{genericDataTimeformat(assignment.startDate)}</span>
                            </p>

                            <p className="mb-2 p-small">
                              Window end: <span className="fw-bold">{genericDataTimeformat(assignment.endDate)}</span>
                            </p>
                          </div>

                          <div className="row px-2 ">
                            <div className="p-2 mb-2">
                              {assignment?.questions.map((question, qindex) => {
                                const isAnswered = assignment.answers?.find((a) => a.questionId === question._id)

                                return (
                                  <Question
                                    key={question._id + '-' + assignment._id}
                                    isAnswered={isAnswered}
                                    question={question}
                                    screeningId={screeningId}
                                    projectId={projectId}
                                    assignmentId={assignment._id}
                                    loadInfo={loadInfo}
                                  ></Question>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )
                : null}
            </div>
          )}
        </div>
      </GroupHeader>
    </div>
  )
}

const Question = ({ question, projectId, screeningId, assignmentId, isAnswered, loadInfo }) => {
  const [selected, setSelected] = useState<number | string>('')

  const onSelect = (answer) => {
    console.log('DebugEligibility > answer = ', answer)
    setSelected(answer)
  }

  const saveAnswer = () => {
    if (!selected) {
      return
    }

    const p = ENDPOINTS.DEBUG.saveAnswer(projectId)
    post(p, {
      screeningId: screeningId,
      projectId: projectId,
      type: question.type,
      value: selected?.toString(),
      assignmentId: assignmentId,
      questionId: question._id,
    })
      .then((result) => {
        console.log('DebugEligibility > result = ', result)
        loadInfo()
        return result
      })
      .catch((error) => {
        console.log('DebugEligibility > error = ', error)
        alert(error.message)
        return Promise.reject(error)
      })
  }

  return (
    <>
      <div
        className="col-12 mb-3 px-3 py-4 rounded position-relative"
        style={{ background: '#ededed', border: isAnswered ? '2px solid green' : 'none' }}
      >
        {isAnswered && (
          <div className="position-absolute w-50" style={{ top: 0, right: 1 }}>
            <p
              className="bg-primary text-white d-inline-block px-3  py-1 p-small "
              style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}
            >
              Already answered
            </p>
          </div>
        )}
        <div className="row " style={{}}>
          <div className="col-12">
            <p className="p-mini opacity-50">
              {question._id} - {question.type}
            </p>
            <p className="mb-2">{question.title['en']}</p>
          </div>

          <div className="col-8">
            {question.type === 'nprs11pt5l' || question.type === 'nprs11pt2l' ? (
              <div className="d-flex align-items-center">
                {question.options?.intensity.map((answer, aindex) => {
                  const op = answer['en'] || aindex
                  return (
                    <Button
                      key={aindex}
                      onClick={() => {
                        onSelect(aindex)
                      }}
                      size="small"
                      type="primary"
                      className="px-2 me-2"
                    >
                      {op}
                    </Button>
                  )
                })}
              </div>
            ) : (
              <Button
                onClick={() => {
                  onSelect('ok')
                }}
                size="small"
                type="primary"
              >
                Answer
              </Button>
            )}
          </div>

          <div className="col-4 d-flex align-items-center justify-content-end">
            {selected ? (
              <>
                <p className="p-small text-black me-2">Selected: {selected ? selected : ''}</p>
                <Button onClick={saveAnswer} size="small">
                  save answer
                </Button>
              </>
            ) : (
              <>
                {isAnswered ? (
                  <p className="text-black me-4">
                    Answer given: <span className="fw-bold"> {isAnswered.value}</span>
                  </p>
                ) : (
                  <p className=" p-small text-black me-4">Select an answer</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
