/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 09/12/2022.
 */
import Drawer from 'antd/lib/drawer'

import React, { Children, ReactNode, Suspense, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'

type Props = {
  projectId: string
  children: any
  title?: string
  width?: number | string
  setVisible?: (value: boolean) => void
  to?: string | null
  header?: ReactNode | ReactNode[]
}

export const ProjectDrawer = ({
  projectId,
  children,
  setVisible,
  width = '40%',
  to = null,
  title = 'Title ',
  header,
}: Props) => {
  const navigate = useNavigate()
  const [state, setState] = useState<boolean>(true)

  const updateVisible = (value: boolean) => {
    if (setVisible) {
      setVisible(true)
    }
  }

  const onClose = () => {
    //setVisible(false)
    console.log(' ProjectDrawer > onClose ')
    setState(false)
  }

  const layoutChange = (e) => {
    const toPath = to === null ? '/project/' + projectId : to

    if (!e) {
      updateVisible(false)
      navigate(toPath)
    }
  }

  return (
    <Drawer
      afterOpenChange={layoutChange}
      title={title}
      destroyOnClose={true}
      width={width}
      placement="right"
      onClose={onClose}
      open={state}
      extra={header}
    >
      {/*{children}*/}

      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
        {/*{React.cloneElement(children, { onClose: onClose })}*/}
        {Children.map(children, (child) => {
          return React.cloneElement(child, { onClose: onClose })
        })}
      </Suspense>
    </Drawer>
  )
}
