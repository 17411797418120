import { FormLabel } from './FormLabel'
import { Select } from 'antd'

import { useEffect, useState } from 'react'
import { get, UseFormReturn } from 'react-hook-form'
import { SelectMultipleFormConfigObject } from '../../Types'

const { Option } = Select

type Props = { formHook: UseFormReturn; data: SelectMultipleFormConfigObject }

export const FormSelectMultiple = ({ data, formHook }: Props) => {
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = formHook

  const errorState = get(errors, data.id)

  const formValue = getValues(data.id)
  const currentValue = !formValue ? data.value : formValue

  const [state, setState] = useState<string | null>(currentValue)

  useEffect(() => {
    register(data.id, data.validation)

    setValue(data.id, data.value)
  }, [register, data.id, data.validation, data.value, setValue])

  const items = data.options.map((item, index) => {
    return (
      <Option key={index} value={item.value} label={item.label} title={item.title}>
        <div>
          <span role="img" aria-label={item.label}>
            {item.symbol}
          </span>
          {item.label}
        </div>
      </Option>
    )
  })

  const handleChange = (value) => {
    setValue(data.id, value)
    setState(value)
  }

  return (
    <div className="w-100 py-1">
      <FormLabel label={data.label} />
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={data.placeholder}
        onChange={handleChange}
        optionLabelProp="label"
        defaultValue={state ? state : []}
        status={errorState ? 'error' : ''}
      >
        {items}
      </Select>
      {errorState ? <div className="p-small text-danger">{data.errorMsg}</div> : null}
    </div>
  )
}
