import { Button, Select } from 'antd'
import { GroupHeader } from '../../components/display/GroupHeader'
import { useParams } from 'react-router'
import { useState } from 'react'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { HTTPMethod, RESOURCE_STATUS } from '../../Types'
import { authStore } from '../../store/auth/authStore'
import { useProject } from '../../hooks/useProject'

export const DebugSendEmail = () => {
  const { projectId } = useParams() as { projectId: string; protocolId: string }
  const { data: projectData } = useProject(projectId)

  const user = authStore((state) => state.user)
  const email = user?.attributes.email || ''

  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>()
  const [selectedStatus, setSelectedLStatus] = useState<RESOURCE_STATUS | undefined>(RESOURCE_STATUS.DRAFT)

  const languageOptions = projectData?.languages
    ? projectData?.languages?.map((lang, index) => {
        return { value: lang.isoCode, label: lang.name }
      })
    : []

  const statusOptions = [
    { value: RESOURCE_STATUS.ACTIVE, label: 'Active' },
    { value: RESOURCE_STATUS.DRAFT, label: 'Draft' },
  ]

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = () => {
    setLoading(true)
    return post(
      ENDPOINTS.DEBUG.sendEmailPinCode(projectId),
      { language: selectedLanguage, status: selectedStatus },
      HTTPMethod.PUT
    )
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        alert(error.message)
        setLoading(false)
      })
  }

  const handleLanguageChange = (value: string) => {
    setSelectedLanguage(value)
  }
  const handleStatusChange = (value: RESOURCE_STATUS) => {
    setSelectedLStatus(value)
  }

  return (
    <div className="row mt-5 group-item" style={{}}>
      <GroupHeader label="Test send an email" byline="Select language in which to send the email">
        <div className="col-12 py-3 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between" style={{ width: '100%' }}>
            <div className="px-2">
              <p className=" text-truncate opacity-75 py-1">
                {'Reset pin code email template. Will send for the current draft protocol.'}
              </p>
              <p className="p-small opacity-50">Email: {email}</p>
            </div>
            <div className=" d-flex justify-content-end">
              <div className="px-2"></div>
              <div className="d-flex justify-content-end align-items-center">
                <div className="px-2">
                  <Select
                    variant="filled"
                    placeholder="Select language"
                    onChange={handleLanguageChange}
                    style={{ width: 150 }}
                    options={languageOptions}
                  />
                </div>
                <div className="px-2">
                  <Select
                    variant="filled"
                    placeholder="Select status"
                    onChange={handleStatusChange}
                    style={{ width: 150 }}
                    options={statusOptions}
                    defaultValue={RESOURCE_STATUS.DRAFT}
                  />
                </div>
                <Button type="primary" loading={loading} onClick={onSubmit} disabled={selectedLanguage === undefined}>
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </GroupHeader>
    </div>
  )
}
