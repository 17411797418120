import { Button } from 'antd'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, Route, Routes, useParams } from 'react-router-dom'

import Alert from 'antd/lib/alert'
import { omit } from 'lodash'
import { CRUD_VIEW_TYPE, PERSONNEL_TYPE_ENUM, VIEW_TYPE } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { getFormType } from '../../components/form/formFactory'
import { post } from '../../service/API'
import { isValidEmail } from '../../util/isValidEmail'
import { populateConfig } from '../../util/populateConfig'
import { InviteTrialstaff } from './InviteTrialstaff'
import { getClinician } from './clinicianConfig'

import { ENDPOINTS } from '../../service/ENDPOINTS'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { JustificationInput } from '../../components/form/JustificationInput'
import { openNotification } from '../../components/notifications/openNotification'
import { useClinicianById } from '../../hooks/useClinicianById'
import { displayStaffStatus } from '../../util/staffUtils'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { StaffPii } from '../trialStaff/StaffPii'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const ClinicianCRUD = ({ onClose, closeDrawer, viewType }: Props) => {
  const { projectId, userId } = useParams() as { projectId: string; userId: string }

  const { data: dataConfig } = useProjectConfig()
  const { data, mutate } = useClinicianById(projectId, userId)

  const configObj = viewType === CRUD_VIEW_TYPE.UPDATE ? populateConfig(getClinician(), data, viewType) : getClinician()

  const formHook = useForm()

  const { handleSubmit } = formHook
  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()

    if (!isValidEmail(formData.email)) {
      setErrorState({ message: 'Please provide a valid email address.' })
      return
    }

    if (formData.phone.countryCode === '' || formData.phone.tel === '' || formData.phone.tel === null) {
      setErrorState({ message: 'Please provide a valid telephone number' })
      return
    } else {
      setErrorState(null)
    }

    const createData = { ...formData }
    const updatedData = { ...data, ...formData }

    const method = viewType === CRUD_VIEW_TYPE.UPDATE ? 'PUT' : 'POST'
    const sendData = viewType === CRUD_VIEW_TYPE.UPDATE ? updatedData : createData
    const omittedObj = omit(sendData, ['inviteSent'])
    const updatedSendData = { ...omittedObj, projectId: projectId, sites: [] }

    return post(ENDPOINTS.CLINICIANS.ACTION.clinician(projectId), updatedSendData, method)
      .then((response) => {
        const existingUser: boolean = response.data.existingUser
        const title = viewType === CRUD_VIEW_TYPE.UPDATE ? 'Update clinician' : 'Create clinician'
        const msg =
          viewType === CRUD_VIEW_TYPE.UPDATE
            ? 'Clinician has been successfully updated'
            : existingUser
            ? 'Existing clinician has been successfully added'
            : 'Clinician has been successfully created'
        openNotification(title, msg, 'happy')

        mutate()

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
          //setAddSiteDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      console.log('ClinicianCRUD > navigateBack = ')
      onClose()
    }
  }

  return (
    <>
      <Routes>
        <Route
          path="/edit/*"
          element={
            <ProjectDrawer projectId={projectId} width="30%" to="../" title="Edit clinician information">
              <StaffPii staffType={PERSONNEL_TYPE_ENUM.CLINICIAN}></StaffPii>
            </ProjectDrawer>
          }
        />
        {/* TODO: Enable when there is a fix for deactivating/reactivating clinicians in backend */}
        {/*<Route*/}
        {/*  path="/retire"*/}
        {/*  element={*/}
        {/*    <ProjectDrawer projectId={projectId} width="30%" to="../" title="Retire/Reactive clinician">*/}
        {/*      <RetireStaff type={PERSONNEL_TYPE_ENUM.CLINICIAN}></RetireStaff>*/}
        {/*    </ProjectDrawer>*/}
        {/*  }*/}
        {/*/>*/}
      </Routes>

      {viewType === CRUD_VIEW_TYPE.CREATE || viewType === CRUD_VIEW_TYPE.UPDATE ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {configObj.map((item, index) => {
              // -------------------------------------------------------------------------------------
              // If time allows, investigate how to do it directly through the config (by injecting)
              // -------------------------------------------------------------------------------------
              const comp = getFormType(item, index, formHook, [], undefined, dataConfig.countries, viewType)
              return comp
            })}
          </div>

          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}

          <div className="col-12 mt-3 mb-4">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>

          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === CRUD_VIEW_TYPE.CREATE ? 'Create user' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <div className="d-flex ">
              <FormLabel label="Name"></FormLabel>
              <p className="ms-2 p-small opacity-25 ">{data ? data._id : '-'}</p>
            </div>
            <h6 className="fw-bold">{data ? data.name : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Email"></FormLabel>
            <h6 className="fw-bold">{data ? data.email : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Phone"></FormLabel>
            <h6 className="fw-bold">{data ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>

          <div className="col-12 mb-4">
            <FormLabel label="Created by"></FormLabel>
            <h6 className="fw-bold">{data ? data.createdBy : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Job title"></FormLabel>
            <h6 className="fw-bold">{data ? data.jobTitle : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Status"></FormLabel>
            <h6 className="fw-bold">{displayStaffStatus(data)}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="training  "></FormLabel>
            <h6 className="fw-bold">ver: {data ? data?.training : '-'}</h6>
          </div>

          <div className="col-12 d-flex justify-content-end mb-4">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Link to="edit">
              <Button type="primary">Edit Clinician</Button>
            </Link>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-12 mt-3">
            <InviteTrialstaff
              data={data}
              mutate={mutate}
              userId={userId}
              userType={PERSONNEL_TYPE_ENUM.CLINICIAN}
              onClose={onClose}
            ></InviteTrialstaff>
          </div>
        </div>
      )}
    </>
  )
}
