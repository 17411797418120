/**
 * Created by Søren Tramm 2023.10.06
 */
import React, { Suspense } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { ProjectDrawer } from '../epro-project/ProjectDrawer'
import { CRUD_VIEW_TYPE } from '../../Types'
import { useProtocolById } from '../../hooks/useProtocolById'
import { ProtocolHeader } from './ProtocolHeader'
import { ProtocolClone } from './ProtocolClone'
import { Button } from 'antd'
import { PhaseListItem } from '../phase/PhaseListItem'
import { useProject } from '../../hooks/useProject'
import { QuestionnaireCRUD } from '../questionnaire/QuestionnaireCRUD'
import { AuditViewListTargetProtocol } from '../audit/AuditViewListTargetProtocol'
import { PhaseCRUD } from '../phase/PhaseCRUD'
import { GroupCRUD } from '../group/GroupCRUD'

type Props = {
  draftExist: boolean
}
export const ProtocolActivePage = ({ draftExist }: Props) => {
  const { projectId, protocolId } = useParams() as { projectId: string; protocolId: string }

  const { data: protocolData } = useProtocolById(projectId, protocolId)
  const { data: projectData } = useProject(projectId)
  const defaultLanguage = projectData.languages[0].isoCode

  const closeDrawer = (value) => {}

  return (
    <>
      <Routes>
        <Route
          path="clone-protocol"
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../" title="Create Protocol">
                <ProtocolClone closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE}></ProtocolClone>
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/phase/:phaseId/questionnaire/:questionnaireId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to="../" title="View questionnaire">
                <QuestionnaireCRUD
                  closeDrawer={closeDrawer}
                  viewType={CRUD_VIEW_TYPE.VIEW}
                  allowEdit={false}
                ></QuestionnaireCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/phase/:phaseId/group/:groupId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to="../" title="View questionnaire">
                <GroupCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW}></GroupCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/phase/:phaseId/group/:groupId/questionnaire/:questionnaireId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to="../" title="View questionnaire">
                <QuestionnaireCRUD
                  closeDrawer={closeDrawer}
                  viewType={CRUD_VIEW_TYPE.VIEW}
                  allowEdit={false}
                ></QuestionnaireCRUD>
              </ProjectDrawer>
            </Suspense>
          }
        />

        <Route
          path="/phase/:phaseId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <ProjectDrawer projectId={projectId} width="30%" to="../" title="View phase">
                <PhaseCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} allowEdit={false} />
              </ProjectDrawer>
            </Suspense>
          }
        />
        <Route
          path="/:protocolId/audit-trail/*"
          element={
            <ProjectDrawer projectId={projectId} width="40%" to="../" title="View audit trail">
              <AuditViewListTargetProtocol closeDrawer={closeDrawer} />
            </ProjectDrawer>
          }
        />
      </Routes>

      <div className="container-fluid mb-5 ">
        <ProtocolHeader
          protocolData={protocolData}
          rightComponent={
            <div className="col-6 pt-4 d-flex justify-content-end align-items-center">
              <div className="mt-2">
                {!draftExist ? (
                  <Link to={`./clone-protocol`}>
                    <Button type="primary">Create new draft</Button>
                  </Link>
                ) : null}
              </div>
            </div>
          }
        ></ProtocolHeader>

        <div className="row" style={{}}>
          <div className="col-4 d-flex justify-content-between my-6 ">
            <div className="d-flex align-items-center">
              <h4>Phases </h4>
              <p className="ms-2 mb-0 pb-1 opacity-75">({protocolData?.phases?.length || 0})</p>
            </div>
          </div>
        </div>

        {protocolData.phases?.map((phase, phaseIndex) => {
          return <PhaseListItem key={phaseIndex} phase={phase} language={defaultLanguage}></PhaseListItem>
        })}
      </div>
    </>
  )
}
