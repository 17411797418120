import { Input } from 'antd'
import { useEffect } from 'react'
import { get, UseFormReturn } from 'react-hook-form'
import { CRUD_VIEW_TYPE, TextFormConfigObject, VIEW_TYPE } from '../../Types'
import { FormLabel } from './FormLabel'

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/04/2022.
 */

type Props = { formHook: UseFormReturn; data: TextFormConfigObject; viewType?: VIEW_TYPE }

export const FormTextField = ({ data, formHook, viewType }: Props) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = formHook

  const errorState = get(errors, data.id)
  const errorMessage = errorState && errorState.message ? (errorState.message as string) : (data.errorMsg as string)

  useEffect(() => {
    register(data.id, { value: data.value, ...data.validation })
  }, [register, data.id, data.validation, data.value])

  useEffect(() => {
    return () => {
      unregister(data.id)
    }
  }, [unregister, data.id])

  /*useEffect(() => {
    if (data.value) {
      setValue(data.id, data.value)
    }
  }, [data.value, data.id, data.validation, register, setValue])*/

  const onChange = (value) => {
    setValue(data.id, value.target.value)
  }

  const currentValue = getValues(data.id) ? getValues(data.id) : data.value

  return (
    <div className="col-12 w-100 py-2">
      <FormLabel label={data.label}>
        {data.postfix ? (
          <p>
            {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
          </p>
        ) : null}
      </FormLabel>

      <Input
        placeholder={data.placeholder}
        onChange={onChange}
        defaultValue={currentValue}
        status={errorState ? 'error' : ''}
        key={`${data.id}:${currentValue}`}
        //
        // TODO: Disabling the email if it's an update since we do not properly handle email / phone updates on the backend
        // We can plan how to handle this next sprint - 05/02/2023
        //
        disabled={
          currentValue && (data.id === 'email' || data.id === 'name') && viewType === CRUD_VIEW_TYPE.UPDATE
            ? true
            : false
        }
      />
      {errorState ? <div className="p-small text-danger">{errorMessage}</div> : null}
    </div>
  )
}
