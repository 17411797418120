/**
 * Created by @author Søren Tramm
 */
import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { LanguageISOCode, QUESTIONNAIRE_STATUS } from '../../../Types'

type Props = { item: any; to?: string; mayClone?: boolean; language: LanguageISOCode }

const getType = (item) => {
  if (item.onboarding) {
    return 'Onboarding'
  }

  if (item?.eligibility) {
    return 'Eligibility'
  }

  return ''
}

export const ProtocolQuestionnaireListItem = ({ item, to = './', mayClone = false, language }: Props) => {
  const languages = Object.keys(item?.name || {})

  const type = getType(item)

  return (
    <div className="col-12 px-2 mb-2">
      <div
        className="row pb-2 bg-white"
        style={{ border: '1px solid ', borderColor: '#CFD3D7 ', boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.08)' }}
      >
        <div className="col-12">
          <div className="w-100 pt-3 pb-2 px-2 d-flex ">
            <div className=" " style={{ width: '25%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Internal Title</p>
              <p className="me-2">{item?.internalTitle}</p>
            </div>
            <div className="" style={{ width: '20%' }}>
              <p className="mb-0 p-mini alpha-80">Languages</p>
              <p className="mb-0 fw-bold text-primary text-capitalize">{languages.join(',')}</p>
            </div>
            <div className="" style={{ width: '15%' }}>
              <div className="">
                <p className="mb-0 p-mini alpha-80">Recurrence</p>
                <p className="mb-0 fw-bold text-primary ">{item?.recurrence?.type || 'Not set'}</p>
              </div>
            </div>
            <div className=" " style={{ width: '10%' }}>
              <p className="mb-0 p-mini alpha-80 fw-bold">Status</p>
              <p className="mb-0 fw-bold text-primary text-capitalize">{item.status.toLowerCase()}</p>
            </div>

            {/* {item.status === QUESTIONNAIRE_STATUS.ACTIVE ? (
              <Popconfirm
                title="Update the questionniare"
                description={
                  <p className="pr-6">
                    You will need to "clone phase" <br />
                    to edit the questionnaire{' '}
                  </p>
                }
                showCancel={false}
                okText="Ok"
              >
                <Button className="me-2">Update Questions</Button>
              </Popconfirm>
            ) : null}
*/}

            <div className="d-flex align-items-center justify-content-end " style={{ width: '10%' }}>
              {type && (
                <div className="rounded-pill d-inline-block px-3 py-1 mt-1 border " style={{ background: '#f1f1f1' }}>
                  <p className=" " style={{ fontSize: '0.75rem', color: '#818181' }}>
                    {type}
                  </p>
                </div>
              )}
            </div>

            <div className="d-flex flex-fill justify-content-end align-items-center" style={{ width: '30%' }}>
              {item.status === QUESTIONNAIRE_STATUS.DRAFT ? (
                <div className="me-2">
                  <Link to={`${to}/questionnaire/${item?._id}/manage-questions`}>
                    <Button>Manage questions</Button>
                  </Link>
                </div>
              ) : null}

              {/*{item.status === QUESTIONNAIRE_STATUS.ACTIVE ? (
                <div className="me-2">
                  <Link to={`${to}/questionnaire/${item?._id}/manage-questions`}>
                    <Button>Clone</Button>
                  </Link>
                </div>
              ) : null}*/}

              <Link to={`${to}/questionnaire/${item?._id}`}>
                <Button>View</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
