/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 29/03/2023.
 */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { HTTPMethod, VIEW_TYPE } from '../../Types'
import { useSWRConfig } from 'swr'
import { openNotification } from '../../components/notifications/openNotification'
import { useGroup } from '../../hooks/useGroup'
import { GroupView } from './GroupView'
import { getGroupCtaElement } from './groupConfig'
import { Alert, Button, Popconfirm } from 'antd'
import { populateConfig } from '../../util/populateConfig'

type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}

export const GroupClone = ({ closeDrawer, onClose }: Props) => {
  const { projectId, groupId, protocolId, phaseId } = useParams() as {
    projectId: string
    groupId: string
    phaseId: string
    protocolId: string
  }
  const { mutate: globalMutate } = useSWRConfig()

  const { data, mutate } = useGroup(projectId, groupId)

  const ctaConfig = populateConfig(getGroupCtaElement, data, null)
  const [errorState, setErrorState] = useState<any>()

  //viewType === 'UPDATE' ? populateConfig(getPhaseConfig(bodyParts), data, viewType) : getPhaseConfig(bodyParts)

  const onSubmit = () => {
    const sendData = { groupId: groupId, phaseId: phaseId }

    return post(ENDPOINTS.GROUPS.ACTION.clone(projectId), sendData, HTTPMethod.POST)
      .then((result) => {
        openNotification('Group cloned', '', 'happy')

        mutate()
        globalMutate(ENDPOINTS.PROTOCOLS.GET_ONE(projectId, protocolId))

        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        setErrorState(error)
      })
  }

  const navigateBack = () => {
    console.log('navigateBack', onClose)
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <GroupView data={data} ctaConfig={ctaConfig}>
        <div className="col-12 mt-3 d-flex justify-content-end">
          <Button className="me-2" type="default" onClick={navigateBack}>
            Cancel
          </Button>

          <Popconfirm
            placement="left"
            title={<p className="pe-4">Please confirm your action</p>}
            description={<p className="pe-4">Do you want to continue with cloning this group</p>}
            onConfirm={onSubmit}
            //onCancel={}
            okText={<p className="px-2">Yes</p>}
            cancelText="No"
          >
            <Button type="primary">Clone</Button>
          </Popconfirm>
        </div>
      </GroupView>
      <div className="col-12 mt-3">
        {errorState ? (
          <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
