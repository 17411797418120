/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 07/11/2024.
 */
import React, { ReactNode } from 'react'
import { LanguageISOCode, Phase, RESOURCE_STATUS } from '../../Types'
import { PhaseContentLabel } from '../phase/PhaseContentLabel'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { ProtocolQuestionnaireListItem } from '../protocol/overview/ProtocolQuestionnaireListItem'
import { useMatch } from 'react-router'

type Props = {
  phase: Phase
  language: LanguageISOCode
  headerButtons?: React.ReactElement | null

  noContent?: ReactNode | null
  isDraft: boolean
}

export const GroupList = ({ phase, headerButtons, language, isDraft = true, noContent = null }: Props) => {
  const doesGroupshaveContent = phase.groups.filter((group) => group.questionnaires.length !== 0).length !== 0

  const isDraftProtocol = useMatch('/project/:projectId/protocol/:protocolId/draft/*') ? true : false
  const GROUP_COLOR = '#8b918c'

  return (
    <>
      {doesGroupshaveContent ? <PhaseContentLabel label="GROUPS">{headerButtons}</PhaseContentLabel> : null}
      <div className="col-12 px-4 " style={{}}>
        <div className="row" style={{}}>
          <div className="col-12 px-2 ">
            {phase.groups.map((group, index) => {
              const isGroupDraft = group.status === RESOURCE_STATUS.DRAFT

              //const isGroupDraftAndPhaseCloned = group.status === RESOURCE_STATUS.DRAFT && isDraftProtocol

              return (
                <div
                  key={index}
                  className="row rounded-1 mb-2 "
                  style={{
                    background: GROUP_COLOR,
                    padding: '5px 3px 3px 3px',
                    boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <p className="p-small my-2 text-white text-capitalize" style={{ letterSpacing: 0.3 }}>
                      <span className="fw-bold">{group.title}</span>{' '}
                      <span className="ps-2 opacity-50 ">{group._id}</span>{' '}
                      <span className="ps-2 opacity-50 ">{group.status}</span>
                    </p>
                    <div className="">
                      {/*Dennis is making a mess here -- will remove asap*/}

                      {/*{isGroupDraft ? (
                        <>
                          <Link to={`phase/${phase._id}/group/` + group._id} className="me-2">
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">Edit group</p>
                            </Button>
                          </Link>

                          <Link to={`phase/${phase._id}/group/${group._id}/questionnaire/create`}>
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">Add questionnaire</p>
                            </Button>
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link to={`phase/${phase._id}/group/${group._id}/clone`}>
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">Clone group</p>
                            </Button>
                          </Link>
                          <Link to={`phase/${phase._id}/group/` + group._id} className="me-2">
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">View group</p>
                            </Button>
                          </Link>
                        </>
                      )}*/}

                      {isDraftProtocol && !isGroupDraft ? (
                        <>
                          <Link to={`phase/${phase._id}/group/${group._id}/clone`}>
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">Clone group</p>
                            </Button>
                          </Link>
                          <Link to={`phase/${phase._id}/group/` + group._id} className="me-2">
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">View group</p>
                            </Button>
                          </Link>
                        </>
                      ) : null}

                      {/* #########################################

                            If the group has been cloned and the protocol is draft

                          ##########################################
                      */}
                      {isDraftProtocol && isGroupDraft ? (
                        <>
                          <Link to={`phase/${phase._id}/group/${group._id}/questionnaire/create`}>
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">Add questionnaire</p>
                            </Button>
                          </Link>
                          <Link to={`phase/${phase._id}/group/` + group._id} className="me-2">
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">View group</p>
                            </Button>
                          </Link>
                        </>
                      ) : null}

                      {!isDraftProtocol ? (
                        /* ############################

                        This  will link the ACTIVE Protocol page

                           ############################
                        */ <>
                          <Link to={`phase/${phase._id}/group/` + group._id} className="me-2">
                            <Button type="text" className="py-0 my-0 px-2">
                              <p className="p-small text-white">View group</p>
                            </Button>
                          </Link>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="rounded-2 pt-2" style={{ background: 'white' }}>
                    {group.questionnaires.length !== 0 ? (
                      group.questionnaires.map((ques, qIndex) => {
                        return (
                          <ProtocolQuestionnaireListItem
                            key={qIndex}
                            item={ques}
                            /**
                             * This looks like a bug. we should align the path between draft and active phases
                             */
                            to={
                              isDraft
                                ? `./phase/${phase._id}/group/` + group._id
                                : `./phase/${phase._id}/group/` + group._id
                            }
                            mayClone={phase.status !== RESOURCE_STATUS.ACTIVE}
                            language={language}
                          />
                        )
                      })
                    ) : (
                      <div className="d-flex justify-content-center ">
                        <div className="d-flex flex-column align-items-center py-1">
                          {isDraft ? (
                            <>
                              <p className="p-small my-2 text-center opacity-75">Add a questionnaire to this group</p>
                              <Link to={`phase/${phase._id}/group/${group._id}/questionnaire/create`} className="mb-3">
                                <Button
                                  type="primary"
                                  size="small"
                                  className="px-3"
                                  style={{ background: GROUP_COLOR }}
                                >
                                  <p className="p-small">Add questionnaire</p>
                                </Button>
                              </Link>
                            </>
                          ) : (
                            <p className="p-small my-2 text-center opacity-75">No questionnaires in this group</p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}

            {isDraft ? <>{noContent}</> : null}
          </div>
        </div>
      </div>
    </>
  )
}
