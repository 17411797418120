/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 08/12/2022.
 */
import React, { Suspense, useState } from 'react'
import { Link, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useProject } from '../../hooks/useProject'
import { a, useTransition } from '@react-spring/web'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { QuestionnairePage } from '../questionnaire/QuestionnairePage'
import { ProjectDrawer } from './ProjectDrawer'
import { SitePage } from '../site/SitePage'
import { ProjectCRUD } from './ProjectCRUD'
import { AUDIT_PAGE, DEBUG_PAGE, PROTOCOL_PAGE, STAFF_PAGE } from '../../pages'
import { DeleteProject } from './DeleteProject'
import { ProjectActivation } from './ProjectActivation'
import { StaffWrapper } from '../trialStaff/StaffWrapper'
import { Button } from 'antd'
import { EproProjectModal } from './EproProjectModal'
import { ProtocolPage } from '../protocol/ProtocolPage'
import { AuditTrailPage } from '../audit/AuditTrailPage'
import { DebugPage } from '../debug/DebugPage'

export const EproProjectView = () => {
  const params: any = useParams()
  const loc = useLocation()
  const subPathStr = params['*'] as string

  const projectId = params.projectId

  const index = subPathStr.indexOf('/')
  const splitIndex = index === -1 ? 0 : index
  const subPath = index === -1 ? subPathStr : subPathStr.slice(0, splitIndex)

  const diff = '/project/' + params.projectId + '/' + subPath //newPathname //location.pathname

  const { data, mutate } = useProject(params.projectId || '')

  const projectData = data

  const abbreviations = data.country.map((country) => country.abbreviation).join(', ')
  const languages = data.languages.map((language) => language.isoCode).join(', ')

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!projectData && params.projectId) {
    throw new Error(`Project does not exist ${params.projectId}`)
  }

  const transitions = useTransition(loc.pathname, {
    key: diff,
    from: { transform: 'translate3d(20%,0,0)', opacity: 0 },
    enter: { transform: 'translate3d(0%,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(-25%,0,0)', opacity: 0 },
  })

  const closeDrawer = (value) => {
    if (!value) {
      mutate()
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className="container-fluid bg-primary" style={{ boxShadow: '0px 2px 5px 1px rgba(0, 0, 0, 0.05)' }}>
        <div className="container py-2 px-2" style={{}}>
          <div className="row py-1" style={{}}>
            <div className="col-12 mb-2 mt-2 d-flex justify-content-between">
              <div className="">
                <p className="p-small p-mini text-white opacity-75 fw-bold">Title</p>
                <p className="mb-0 p-nav text-white">{projectData.title}</p>
              </div>
              <div className="">
                <p className="p-small p-mini text-white opacity-75">Study ID</p>
                <p className="mb-0 text-white ">{projectData?.studyId || '00-000'}</p>
              </div>

              <div className="">
                <p className="p-small p-mini text-white fw-bold opacity-75">Countries</p>
                <p className="mb-0 p-nav text-white">{abbreviations ? abbreviations.toUpperCase() : '-'}</p>
              </div>
              <div className="">
                <p className="p-small p-mini text-white fw-bold opacity-75">Languages</p>
                <p className="mb-0 p-nav text-white">{languages ? languages.toUpperCase() : '-'}</p>
              </div>
              <div className="">
                <p className="p-small p-mini text-white opacity-75">Condition</p>
                <p className="mb-0 p-nav text-white ">{projectData.condition}</p>
              </div>
              <div className="">
                <p className="p-small p-mini text-white opacity-75">Status</p>
                <p className="mb-0 p-nav text-white ">{projectData.status ? projectData.status : '-'}</p>
              </div>
              <div className="">
                <Link to="edit">
                  <Button className="mt-1 me-3" type="default">
                    Edit project
                  </Button>
                </Link>
                <Button className="mt-1" type="primary" onClick={showModal}>
                  Update status
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EproProjectModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setOpen={setIsModalOpen}
      ></EproProjectModal>

      <div className="container">
        <Routes>
          <Route
            path="/edit/*"
            element={
              <>
                <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                  <ProjectDrawer
                    projectId={projectId}
                    setVisible={closeDrawer}
                    to="../protocol"
                    title="View/Edit Project"
                  >
                    <ProjectCRUD closeDrawer={closeDrawer} viewType="UPDATE"></ProjectCRUD>
                  </ProjectDrawer>
                </Suspense>
              </>
            }
          />
          <Route
            path="edit/delete"
            element={
              <>
                <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                  <ProjectDrawer projectId={projectId} setVisible={closeDrawer} to="../protocol" title="Delete project">
                    <DeleteProject closeDrawer={closeDrawer}></DeleteProject>
                  </ProjectDrawer>
                </Suspense>
              </>
            }
          />
          <Route
            path="*"
            element={
              <div className="position-relative" style={{ left: 0, top: 0 }}>
                {transitions((styles, item) => {
                  return (
                    <a.div className="position-absolute w-100" style={{ ...styles }}>
                      <Routes location={item}>
                        <Route
                          path="/site/*"
                          element={
                            <>
                              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                                <SitePage></SitePage>
                              </Suspense>
                            </>
                          }
                        />

                        <Route
                          path="/questionnaire/*"
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <QuestionnairePage></QuestionnairePage>
                            </Suspense>
                          }
                        />

                        <Route
                          path="/activation/*"
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <ProjectActivation></ProjectActivation>
                            </Suspense>
                          }
                        />

                        <Route
                          path={STAFF_PAGE.path}
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <StaffWrapper></StaffWrapper>
                            </Suspense>
                          }
                        />

                        <Route
                          path={PROTOCOL_PAGE.path}
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <ProtocolPage />
                            </Suspense>
                          }
                        />

                        <Route
                          path={AUDIT_PAGE.path}
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <AuditTrailPage />
                            </Suspense>
                          }
                        />

                        <Route
                          path={DEBUG_PAGE.path}
                          element={
                            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                              <DebugPage />
                            </Suspense>
                          }
                        />
                      </Routes>
                    </a.div>
                  )
                })}
              </div>
            }
          />
        </Routes>
      </div>
    </>
  )
}
