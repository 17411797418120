import { Slider } from 'antd'
import React from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { FormLabel } from './FormLabel'
import { SliderRangeFormConfigObject } from '../../Types'

type Props = { formHook: UseFormReturn; data: SliderRangeFormConfigObject }

export const FormSliderRange = ({ data, formHook }: Props) => {
  const { control } = formHook
  const defaultMin = data.options[0]
  const defaultMax = data.options[1]
  console.log('FormSliderRange > defaultMax = ', defaultMax)
  return (
    <Controller
      name={data.id}
      control={control}
      render={({ field: { onChange, value } }) => {
        const firstLabel = value ? value[0] : defaultMin

        return (
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <FormLabel label={data.label}>
                {/*{data.postfix ? (
                  <p>
                    {data.label} <span className="opacity-75 p-small">{data.postfix}</span>
                  </p>
                ) : null}*/}
              </FormLabel>
              <p className="ms-1 p-small noselect">
                <span className="opacity-75 pe-1">{data.prefix}</span>
                <span className="fw-bold">{firstLabel}</span>
                <span className="opacity-75 px-1">-</span>
                <span className="opacity-75 pe-1">{data.postfix}</span>
                <span className="fw-bold">{value ? value[1] : defaultMax}</span>
              </p>
            </div>
            <Slider
              range
              min={defaultMin}
              max={defaultMax}
              defaultValue={[defaultMin, defaultMax]}
              step={0.1}
              onChange={onChange}
              value={value}
            />
          </div>
        )
      }}
    />
  )
}
