import { CheckCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Checkbox, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { mutate as mutateGlobal } from 'swr'
import { CRUD_VIEW_TYPE, VIEW_TYPE } from '../../Types'
import { FormLabel } from '../../components/form/FormLabel'
import { JustificationInput } from '../../components/form/JustificationInput'
import { getFormType } from '../../components/form/formFactory'
import { openNotification } from '../../components/notifications/openNotification'
import { useByEndpoint } from '../../hooks/useByEndpoint'
import { useProject } from '../../hooks/useProject'
import { useProjectConfig } from '../../hooks/useProjectConfig'
import { post } from '../../service/API'
import { ENDPOINTS } from '../../service/ENDPOINTS'
import { populateConfig } from '../../util/populateConfig'
import { getSiteConfig } from './siteConfig'
type Props = {
  closeDrawer?: (value) => void
  onClose?: () => void
  viewType?: VIEW_TYPE
}
export const SiteCRUD = ({ closeDrawer, onClose, viewType = 'CREATE' }: Props) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const p = siteId ? ENDPOINTS.SITES.byIdLocalTime(projectId, siteId) : ''

  const { data: dataConfig } = useProjectConfig()
  const { data, mutate } = useByEndpoint(p)
  const { data: projectData } = useProject(projectId)

  /**
   * Done for backwards compatibility
   *
   * On create we want to take all the project languages and convert them to false
   */
  const initialLangData =
    viewType === 'CREATE'
      ? projectData.languages.map((language) => ({ ...language, active: false }))
      : data?.languages || []

  useEffect(() => {
    if (data?.languages) {
      setLangData(data.languages)
    }
  }, [data?.languages])

  // Initialize langData state with initialLangData
  const [langData, setLangData] = useState(initialLangData)

  const [table, setTable] = useState([])
  const [tableKey, setTableKey] = useState(0)

  useEffect(() => {
    if (projectData.languages.length > 0) {
      const updatedTable = projectData.languages.map((language) => ({
        key: language.isoCode,
        name: language.name,
        activeProject: language.active,
        activeSite: data ? !!data.languages.find((lang) => lang.isoCode === language.isoCode && lang.active) : false,
        isoCode: language.isoCode,
      }))
      setTable(updatedTable)

      /**
       * Only way I could figure how to make the table update
       */
      setTableKey((prevKey) => prevKey + 1) // Increment table key to force re-render
    }
  }, [projectData, data])

  const [localSiteDate, localSiteTime] = (data?.localTime || '-, -').split(', ')

  const config = useMemo(() => {
    const configObj =
      viewType === 'UPDATE'
        ? populateConfig(getSiteConfig(dataConfig?.timezones), data, viewType)
        : getSiteConfig(dataConfig?.timezones)
    return configObj
  }, [dataConfig?.timezones, data, viewType])

  const formHook = useForm()

  const { handleSubmit } = formHook

  const [errorState, setErrorState] = useState<any>()

  const onSubmit = (formData, e) => {
    e.preventDefault()
    if (!isValidSiteNumber(formData.siteNumber)) {
      setErrorState({
        message: 'Site number can contain the lower case letters a to z, the numbers 0 to 9',
      })
      return
    } else {
      setErrorState(null)
    }
    const createData = { ...formData, projectId: projectId, languages: langData }
    const updatedData = { ...data, ...formData, languages: langData }
    const method = viewType === 'UPDATE' ? 'PUT' : 'POST'
    const sendData = viewType === 'UPDATE' ? updatedData : createData

    return post(ENDPOINTS.SITES.ACTION(projectId), sendData, method)
      .then((result) => {
        mutate()
        const key = ENDPOINTS.SITES.byProjectId(projectId)
        mutateGlobal(key)
        console.log('result = ', result)
        openNotification('Site successfully updated', '', 'happy')
        if (onClose) {
          onClose()
        }
        if (closeDrawer) {
          closeDrawer(false)
        }
      })
      .catch((error) => {
        console.log('error = ', error)
        openNotification('Site update failed: ', error.message, 'sad')
        setErrorState(error)
      })
  }

  const onError = (err, e) => {
    e.preventDefault()
  }

  const navigateBack = () => {
    if (onClose) {
      onClose()
    }
  }

  const isValidSiteNumber = (value: string) => {
    return /^[a-z0-9]+$/.test(value) // /^(?!.*\s{2,})[a-z0-9\s]+$/.test(value)
  }

  const onChange = (isoCode, checked) => {
    const updatedLangData = langData.map((lang) => {
      if (lang.isoCode === isoCode) {
        return { ...lang, active: checked }
      }
      return lang
    })
    setLangData(updatedLangData)
  }

  const greenCheckV3 = <CheckCircleOutlined style={{ fontSize: '18px', color: '#526b5b' }} />

  const columns = [
    {
      title: 'Language',
      dataIndex: 'name',
      key: 'name',
      className: 'p-small',
    },
    {
      title: 'Active on Project',
      dataIndex: 'activeProject',
      key: 'activeProject',
      className: 'p-small',
      render: (active, record) => {
        return (
          <div className="ms-2" style={{}}>
            {viewType === 'VIEW' ? active ? greenCheckV3 : <></> : <Checkbox disabled={true} defaultChecked={active} />}
          </div>
        )
      },
    },
    {
      title: 'Active on Site',
      dataIndex: 'activeSite',
      key: 'activeSite',
      className: 'p-small',
      render: (active, record) => {
        return (
          <div className="ms-2" style={{}}>
            {viewType === 'VIEW' ? (
              active ? (
                greenCheckV3
              ) : (
                <></>
              )
            ) : (
              <Checkbox
                /**
                 * The !! operator is used to coerce the result of the logical expression into a boolean value.
                 * If the language exists and is active, !! will result in true; otherwise, it will result in false.
                 */
                defaultChecked={record.activeSite}
                onChange={(e) => onChange(record.isoCode, e.target.checked)}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      {viewType === 'CREATE' || viewType === 'UPDATE' ? (
        <form className="row" onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="col-12">
            {config.map((item, index) => {
              // -------------------------------------------------------------------------------------
              // If time allows, investigate how to do it directly through the config (by injecting)
              // -------------------------------------------------------------------------------------
              const comp = getFormType(item, index, formHook, [], undefined, dataConfig?.countries)
              return comp
            })}
          </div>
          <div className="mt-2 mb-3">
            <FormLabel label="Site languages"></FormLabel>
            <Table key={tableKey} columns={columns} dataSource={table} size="small" pagination={false} bordered />
          </div>
          {/*Justification*/}
          {viewType === CRUD_VIEW_TYPE.UPDATE ? <JustificationInput formHook={formHook} /> : null}
          <div className="col-12 mt-3">
            {errorState ? (
              <Alert showIcon message={errorState.type} description={errorState.message} type="error" />
            ) : (
              <></>
            )}
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <Button className="me-2" onClick={navigateBack}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit, onError)}>
              {viewType === 'CREATE' ? 'Create site' : 'Save changes'}
            </Button>
          </div>
        </form>
      ) : (
        <div className="">
          <div className="col-12 mb-4">
            <div className="col-12 mb-4">
              <FormLabel label="Site number"></FormLabel>
              <h6 className="fw-bold">{data?.siteNumber || '-'}</h6>
            </div>
            <FormLabel label="Site name"></FormLabel>
            <h6 className="fw-bold">{data?.title || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site address"></FormLabel>
            <h6 className="fw-bold">{data?.address || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site phone number"></FormLabel>
            <h6 className="fw-bold">{data.phone ? data.phone.countryCode + ' ' + data.phone.tel : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person name"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonName || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person email"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonEmail || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site contact person job title"></FormLabel>
            <h6 className="fw-bold">{data?.contactPersonJobTitle || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Time zone"></FormLabel>
            <h6 className="fw-bold">{data?.timeZone || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local date"></FormLabel>
            <h6 className="fw-bold">{localSiteDate ? localSiteDate : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site local time (24-hour clock)"></FormLabel>
            <h6 className="fw-bold">{localSiteTime ? localSiteTime : '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Active"></FormLabel>
            <h6 className="fw-bold">{data?.active === true ? 'Yes' : 'No' || '-'}</h6>
          </div>
          <div className="col-12 mb-4">
            <FormLabel label="Site languages"></FormLabel>
            <Table key={tableKey} columns={columns} bordered dataSource={table} size="small" pagination={false} />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Link to="edit">
              <Button type="primary">Edit site</Button>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
